module controllers {
    export module master {
        interface ICustomsPartyUpdateScope extends ng.IScope {
        }

        interface ICustomsPartyUpdateParams extends ng.ui.IStateParamsService {
            antId: number;
        }

        export class customsPartyUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'documentRepositoryService',
                'lookupService',
                "supplierService",
                "localeService",
                'customsPartyService',
                'authorisedDealerService'
            ];


            antId: number;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            selectedTab: number = 0;

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            IsDisabled: boolean = false;

            customsParty: interfaces.master.ICustomsPartyMaster;
      
            constructor(
                private $scope: ICustomsPartyUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: ICustomsPartyUpdateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                private supplierService: interfaces.master.ISupplierService,
                private localeService: interfaces.applicationcore.ILocaleService,
                private customsPartyService: interfaces.master.ICustomsPartyService,
                public authorisedDealerService: interfaces.tariff.IAuthorisedDealerService
            ) {

                this.antId = $stateParams.antId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.LoadCustomsParty(this.antId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ant.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
                
            }



            LoadCustomsParty(Id : number)
            {
                return this.customsPartyService.getCustomsParty().query({ Id: Id }, (data: interfaces.master.ICustomsPartyMaster) => {

                    this.customsParty = data;

                    this.breadCrumbDesc = this.customsParty.CompanyCode;

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        //this.gvwInvoices.data = this.payment.InvoiceList;
                        //this.gvwLC.data = this.payment.LetterOfCreditList;
                        //this.loadControls();
                        this.isLoadingDetail = false;
                    });
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            Close() {
                this.$state.go("^");
            }

            saveCustomsParty() {

                
                return this.customsPartyService.save().save(this.customsParty, (data: interfaces.applicationcore.IMessageHandler) => {
                     this.generalService.displayMessageHandler(data);
                                   
                  
                 }, (errorResponse) => {
                     this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
 
                 }).$promise;
             };
 
              Save() {
                 this.bsLoadingOverlayService.wrap({
                     referenceId: 'ant.update'
                 }, () => {
                     return this.saveCustomsParty().then((result) => {
                         this.generalService.displayMessageHandler(result);
                         if (!result.HasErrorMessage) {
                             this.$timeout(() => {
                                 this.bsLoadingOverlayService.wrap({
                                     referenceId: 'ant.update'
                                 }, () => {
                                     return this.LoadCustomsParty(this.antId);
                                 });
                             });
                         }
                     });
                 });
             }
 
             SaveClose() {
                 this.bsLoadingOverlayService.wrap({
                     referenceId: 'ant.update'
                 }, () => {
                     return this.saveCustomsParty().then(() => {
                         this.Close();
                     }, (failureData) => {
                         this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                         this.$anchorScroll("topAnchor");
                     });
                 });
             }

          
        };

        angular.module("app").controller("customsPartyUpdateCtrl", controllers.master.customsPartyUpdateCtrl);
    }
}
